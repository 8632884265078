import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, IconButton } from "@mui/material";
import classes from "./OccupancyRateBarLineCharts.module.scss";
import { OCCUPANCY_BY_YEAR_REQUEST } from "../../../redux/actions";
import { yearlyOccupancySelector } from "../../../redux/selectors/occupancyRateSelector";
import {
  getCurrentYearString,
  getPreviousYearString,
  toDoublePercent,
} from "../../../utils/commonUtils";
import {
  shortMonthsNames,
  yearsList,
} from "../../../utils/constants/periodLists";
import { IYearlyOccupancy } from "../../interfaces";
import { Legend } from "../ChartLegend/ChartLegend";
import { PeriodDropdown } from "../PeriodDropdown/PeriodDropdown";
import { OccupancyRateBar } from "./components/OccupancyRateBar/OccupancyRateBar";
import { OccupancyRateLineChart } from "./components/OccupancyRateLineChart/OccupancyRateLineChart";
import { BarIcon } from "../../../assets/icons/BarIcon";
import { LineIcon } from "../../../assets/icons/LineIcon";
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";
import { performanceDataFilterSelector } from "../../../redux/selectors/performanceDataSelector";

export const OccupancyRateBarLineCharts = (props: {
  bothCharts: boolean;
  page: "performance" | "occupancy";
}) => {
  const dispatch = useDispatch();
  const { bothCharts, page } = props;
  const [fromYear, setFromYear] = useState(getPreviousYearString());
  const [toYear, setToYear] = useState(getCurrentYearString());
  const [chartType, setChartType] = useState("bar");

  const filters = useSelector(performanceDataFilterSelector);
  const isMobileView = useSelector(isMobileModeSelector);
  const isBarActive = chartType === "bar";
  const years = yearsList();

  const fromYearData = useSelector(yearlyOccupancySelector(fromYear));
  const toYearData = useSelector(yearlyOccupancySelector(toYear));

  const occupancyBarLegend = [
    {
      label: fromYear,
      color: "#C1C3D7",
      legendType: "circle",
    },
    {
      label: toYear,
      color: "#5D6293",
      legendType: "circle",
    },
  ];

  const filteredData = useMemo(() => {
    if (!fromYearData || !toYearData) return {};
  
    // Define the shape of the data for combinedData
    type CombinedDataType = {
      name: string;
      [key: string]: string | number; // Allow dynamic keys for year totals
    };
  
    // Filtered data for is_listed == 1 (first 12 months)
    const unlistedData = fromYearData.slice(0, 12).map((monthData: IYearlyOccupancy, index: number) => {
        const fromYearTotal = 
        (monthData?.occupancy_rate_direct ?? 0) +
        (monthData?.occupancy_rate_guest ?? 0) +
        (monthData?.occupancy_rate_guestyical ?? 0) +
        (monthData?.occupancy_rate_ical ?? 0) +
        (monthData?.occupancy_rate_portal ?? 0);

        const toYearTotal = 
          (toYearData[index]?.occupancy_rate_direct ?? 0) +
          (toYearData[index]?.occupancy_rate_guest ?? 0) +
          (toYearData[index]?.occupancy_rate_guestyical ?? 0) +
          (toYearData[index]?.occupancy_rate_ical ?? 0) +
          (toYearData[index]?.occupancy_rate_portal ?? 0);
  
      return {
        name: shortMonthsNames[index],
        [fromYear]: fromYearTotal,
        [toYear]: toYearTotal,
      };
    });  
    
    var listedData = fromYearData.slice(12, 24);
    if(listedData.length > 0)
    {
      listedData = fromYearData.slice(12, 24).map((monthData: IYearlyOccupancy, index: number) => {
        const fromYearTotal = 
        (monthData?.occupancy_rate_direct ?? 0) +
        (monthData?.occupancy_rate_guest ?? 0) +
        (monthData?.occupancy_rate_guestyical ?? 0) +
        (monthData?.occupancy_rate_ical ?? 0) +
        (monthData?.occupancy_rate_portal ?? 0);
  
      const toYearTotal = 
        (toYearData[index + 12]?.occupancy_rate_direct ?? 0) +
        (toYearData[index + 12]?.occupancy_rate_guest ?? 0) +
        (toYearData[index + 12]?.occupancy_rate_guestyical ?? 0) +
        (toYearData[index + 12]?.occupancy_rate_ical ?? 0) +
        (toYearData[index + 12]?.occupancy_rate_portal ?? 0);
    
        return {
          name: shortMonthsNames[index],
          [fromYear]: fromYearTotal,
          [toYear]: toYearTotal,
        };
      });

    }else{
      listedData = unlistedData;
    }
    
  
    // Apply the filters for listed and unlisted data
    const filterYes = filters?.listed?.yes ?? true;
    const filterNo = filters?.listed?.no ?? false;
    const filterBeds = filters?.ownersToRequest?.length> 0 ? filters.ownersToRequest.length : false;   
    // console.log(filterBeds);
  
    let combinedData: CombinedDataType[] = [];
  
    if (filterYes && filterNo && !filterBeds) {
      combinedData = listedData.map((listed: CombinedDataType, index: number) => {
        const unlisted = unlistedData[index] || { [fromYear]: 0, [toYear]: 0 };
  
        return {
          name: listed.name,
          [fromYear]: toDoublePercent((listed[fromYear] as number) + (unlisted[fromYear] as number)),
          [toYear]: toDoublePercent((listed[toYear] as number) + (unlisted[toYear] as number)),
        };
      });
    } else if (filterYes) {
      combinedData = listedData.map((d: CombinedDataType) => ({
        ...d,
        [fromYear]: toDoublePercent(d[fromYear] as number),
        [toYear]: toDoublePercent(d[toYear] as number),
      }));
    } else if (filterNo) {
      combinedData = unlistedData.map((d: CombinedDataType) => ({
        ...d,
        [fromYear]: toDoublePercent(d[fromYear] as number),
        [toYear]: toDoublePercent(d[toYear] as number),
      }));
    }
  
    return {
      [fromYear]: combinedData.filter(Boolean),
      [toYear]: combinedData.filter(Boolean),
    };
  }, [fromYearData, toYearData, filters]); // Recalculate when data or filters change
  
   // const generalData = useMemo(() => {
  //   if (!fromYearData || !toYearData) return {}; 
  //   // Combine data for the two years
  //   const combinedData = fromYearData.slice(0, 12).map((monthData: IYearlyOccupancy, index: number) => {
  //     const fromYearTotal =
  //       monthData.occupancy_rate_direct +
  //       monthData.occupancy_rate_guest +
  //       monthData.occupancy_rate_guestyical +
  //       monthData.occupancy_rate_ical +
  //       monthData.occupancy_rate_portal;
  
  //     const toYearTotal =
  //       toYearData[index].occupancy_rate_direct +
  //       toYearData[index].occupancy_rate_guest +
  //       toYearData[index].occupancy_rate_guestyical +
  //       toYearData[index].occupancy_rate_ical +
  //       toYearData[index].occupancy_rate_portal;
  
  //     return {
  //       name: shortMonthsNames[index],
  //       [fromYear]: fromYearTotal,
  //       [toYear]: toYearTotal,
  //     };
  //   });
  
  //   return {
  //     [fromYear]: combinedData,
  //     [toYear]: combinedData,
  //   };
  // }, [fromYearData, toYearData]); // Recalculate when data changes
  
  
  // const isUser = +(sessionStorage.getItem("ROLE_TYPE") || 3) === 3;
  // const currentData = 
  //   Array.isArray(filteredData) && filteredData.length > 0
  //     ? filteredData
  //     : isUser 
  //       ? generalData 
  //       : []; // Default to empty array if not a user and filteredData is empty


  

  useEffect(() => {
    if (fromYear !== toYear) {
      dispatch({
        type: OCCUPANCY_BY_YEAR_REQUEST,
        payload: filters
          ? {
              owners: filters.ownersToRequest,
              properties: filters.propertiesToRequest,
              beds: filters.bedroomsToRequest,
              year: [fromYear, toYear],
            }
          : {
              owners: [],
              properties: [],
              beds: [],
              year: [fromYear, toYear],
            },
      });
    }
  }, [dispatch, filters, fromYear, toYear]);

  return (
    <div
      className={
        bothCharts
          ? classes.occupancyChartsContainer
          : `${classes.occupancyChartsContainer} ${classes.occupancyBarContainer}`
      }
      id="occupancy_rate"
    >
      <div className={classes.occupancyChartsHeader}>
        <h4 style={{ fontSize: isMobileView ? "16px" : "24px" }}>
          Occupancy Rate:
        </h4>
        <div className={classes.periodBlock}>
          <PeriodDropdown
            className={classes.standardDropdown}
            period={fromYear}
            periodList={years}
            setPeriod={setFromYear}
            variant="standard"
          />
          <h4 className={classes.centerSelfAlign}> vs. </h4>
          <PeriodDropdown
            className={classes.standardDropdown}
            period={toYear}
            periodList={years}
            setPeriod={setToYear}
            variant="standard"
          />
        </div>
        {!isMobileView ? <Divider flexItem orientation="vertical" /> : null}
        {!isMobileView ? (
          <div className={classes.legendRow}>
            <Legend legendData={occupancyBarLegend} />
            {bothCharts && (
              <div className={classes.switcher}>
                <IconButton
                  classes={{ root: classes.button }}
                  className={isBarActive ? classes.activeButton : ""}
                  color={isBarActive ? "secondary" : "inherit"}
                  aria-label="change chart type"
                  onClick={() => setChartType("bar")}
                >
                  <BarIcon />
                </IconButton>
                <IconButton
                  classes={{ root: classes.button }}
                  className={!isBarActive ? classes.activeButton : ""}
                  color={!isBarActive ? "secondary" : "inherit"}
                  aria-label="change chart type"
                  onClick={() => setChartType("line")}
                >
                  <LineIcon />
                </IconButton>
              </div>
            )}
          </div>
        ) : null}
      </div>

      {/* Render the chart based on filteredData */}
      {isBarActive && (
        <OccupancyRateBar
          data={filteredData[fromYear] || []} // Use the filtered data based on the selected year
          fromYear={fromYear}
          toYear={toYear}
          page={page}
        />
      )}
      {bothCharts && !isBarActive && (
        <OccupancyRateLineChart
          data={filteredData[fromYear] || []} // Use the filtered data based on the selected year
          fromYear={fromYear}
          toYear={toYear}
          page={page}
        />
      )}

      {isMobileView ? (
        <div className={classes.legendRow}>
          <Legend legendData={occupancyBarLegend} />
          {bothCharts && (
            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
