import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import classes from "./RevenueTable.module.scss";
import { GET_YEARLY_REVENUE_REQUEST } from "../../../../../redux/actions";
import { yearlyRevenueSelector } from "../../../../../redux/selectors/revenueSelector";
import { getFormattedCurrency } from "../../../../../utils/commonUtils";
import { SortingAscIcon } from "../../../../../assets/icons/SortingAscIcon";
import { SortingDescIcon } from "../../../../../assets/icons/SortingDescIcon";
import {
  performanceDataFilterSelector,
  performanceRevenueFilterSelector,
} from "../../../../../redux/selectors/performanceDataSelector";
import { IRevenueByYearElement } from "../../../../../api/revenueAPI";
import { CheckboxRevenueOption } from "../../../PerfomancePage/components/FiltersContainer/components/RevenuePopover/RevenuePopover";

type AggregatedRow = {
  YEAR: number;
  Revenue: number;
};

export const RevenueTable = () => {
  const dispatch = useDispatch();
  const [sortingFlow, setSortingFlow] = useState(true);
  const rows: IRevenueByYearElement[] = useSelector(yearlyRevenueSelector);
  const isAdmin = +(sessionStorage.getItem("ROLE_TYPE") || 1) === 1;

  const filters = useSelector(performanceDataFilterSelector);
  const revenue_filters = useSelector(performanceRevenueFilterSelector);

  const aggregatedRows: AggregatedRow[] = useMemo(() => {
    if (!rows || !rows.length) {
      return [];
    }

    const filteredRows = rows.filter((row: IRevenueByYearElement) =>
      filters
        ? !!row.is_listed
          ? filters.listed.yes
          : filters.listed.no
        : row.is_listed
    );   

    const mappedRows: AggregatedRow[] = filteredRows.map((el: IRevenueByYearElement) => {
      const fromYearFees = revenue_filters
        ? revenue_filters.reduce(
            (acc: number, current: CheckboxRevenueOption) =>
              acc +
              (current.checked
                ? current.value === "+"
                  ? el[current.key as keyof IRevenueByYearElement]
                  : -el[current.key as keyof IRevenueByYearElement]
                : 0),
            0
          )
        : isAdmin
        ? el.Revenue
        : el.Revenue;

      return {
        YEAR: el.YEAR,
        Revenue: fromYearFees,
      } as AggregatedRow;
    });

    // console.log(filteredRows);

    // Aggregate revenue by year
    const aggregatedResult = mappedRows.reduce<Record<number, AggregatedRow>>((acc, current) => {
      const { YEAR, Revenue } = current;
      if (!acc[YEAR]) {
        acc[YEAR] = { YEAR, Revenue: 0 };
      }
      acc[YEAR].Revenue += Revenue;
      return acc;
    }, {});

    const aggregatedArray = Object.values(aggregatedResult);

    return sortingFlow ? aggregatedArray : aggregatedArray.reverse();
  }, [filters, revenue_filters, rows, sortingFlow]);

  useEffect(() => {
    dispatch({
      type: GET_YEARLY_REVENUE_REQUEST,
      payload: filters
        ? {
            owners: filters.ownersToRequest,
            properties: filters.propertiesToRequest,
            beds: filters.bedroomsToRequest,
          }
        : { owners: [], properties: [], beds: [] },
    });
  }, [dispatch, filters]);

  const handleSorting = useCallback(() => setSortingFlow((prev) => !prev), []);

  return (
    <div className={classes.tableContainer} id="revenueByYear">
      <h2 className={classes.header}>
        Revenue by Year
        <IconButton onClick={handleSorting}>
          {sortingFlow ? <SortingAscIcon /> : <SortingDescIcon />}
        </IconButton>
      </h2>
      <TableContainer className={classes.tableWrapper}>
        <Table
          size="small"
          aria-label="Revenue table"
          className={classes.table}
          stickyHeader
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="center">Year</TableCell>
              <TableCell align="center">Revenue</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {aggregatedRows &&
              aggregatedRows.map((row, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell align="center">{row.YEAR}</TableCell>
                  <TableCell align="center">
                    {getFormattedCurrency(row.Revenue)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter className={classes.stickyTotal}>
            <TableRow className={classes.tableTotal}>
              <TableCell align="center">
                <h4>Total</h4>
              </TableCell>
              <TableCell align="center">
                <h4>
                  {aggregatedRows &&
                    getFormattedCurrency(
                      aggregatedRows.reduce(
                        (prev, current) => prev + current.Revenue,
                        0
                      )
                    )}
                </h4>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
