import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./OccupancyRatePie.module.scss";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { occupancyRatesPeriodList } from "../../../../../utils/constants/periodLists";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { Legend } from "../../../../common/ChartLegend/ChartLegend";
import { RESERVATIONS_VS_BLOCKS_OCCUPANCY_REQUEST } from "../../../../../redux/actions";
import {
  OCCUPANCY_PIE_COLORS,
  RADIAN,
} from "../../../../../utils/constants/chartConstants";
import { Box, Divider, Grid } from "@mui/material";
import { BasicTooltip } from "../../../../common/BasicTooltip/BasicTooltip";
import { N30D, L30D } from "../../../../../utils/constants/filterConstants";
import { occupancyRatesSelector } from "../../../../../redux/selectors/occupancyRateSelector";
import { isNumber, toWholePercent } from "../../../../../utils/commonUtils";
import { Toast } from "../../../../common/Toast/Toast";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { DynamicIndicator } from "../../../../common/DynamicIndicator/DynamicIndicator";
import { OccupancyRateVsMarket } from "../OccupancyRateVsMarket/OccupancyRateVsMarket";
import { performanceDataFilterSelector } from "../../../../../redux/selectors/performanceDataSelector";
import { IOccupancyRates } from "../../../../../api/occupancyRateAPI";

const occupancyPieLegend = [
  {
    label: "Paid Reservations",
    color: "#00B48D",
    legendType: "circle",
  },
  {
    label: "Owner Blocks",
    color: "#5D6293",
    legendType: "circle",
  },
];

export const OccupancyRatePie = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L30D);
  const occupancyRates: IOccupancyRates = useSelector(occupancyRatesSelector);
  const isMobileView = useSelector(isMobileModeSelector);

  const filters = useSelector(performanceDataFilterSelector);

  const preparedValues = useMemo(() => {
    if (occupancyRates) {
     // Convert object to array if necessary
    const occupancyRatesArray = Object.values(occupancyRates);

    // Slice to get first N or separate out
    const listedData = occupancyRatesArray.filter(item => item.is_listed === 1).slice(0,2);
    var unlistedData = occupancyRatesArray.filter(item => item.is_listed === 0).slice(0,2);

    // Determine filters
    const filterYes = filters?.listed?.yes ?? true;
    const filterNo = filters?.listed?.no;
    const filterBeds = filters?.ownersToRequest?.length> 0 ? filters.ownersToRequest.length : false;

    var generalData = [];

    if (filterYes && filterNo && !filterBeds) {
      switch (period) {
        case L30D:
          return {
            ownersBlocksRatio: (listedData[0]?.owners_occupancy_L30D ?? 0) + (unlistedData[0]?.owners_occupancy_L30D ?? 0),
            paidReservationsRatio: (listedData[0]?.paid_reservations_L30D ?? 0) + (unlistedData[0]?.paid_reservations_L30D ?? 0),
            marketOR_current: (listedData[0]?.Market_OR_L30D ?? 0) + (unlistedData[0]?.Market_OR_L30D ?? 0),
            marketOR_prev: (listedData[0]?.Market_OR_L30_60D ?? 0) + (unlistedData[0]?.Market_OR_L30_60D ?? 0),
            or_current:
            (listedData[0]?.owners_occupancy_L30D ?? 0) + (unlistedData[0]?.owners_occupancy_L30D ?? 0) +
            (listedData[0]?.paid_reservations_L30D ?? 0) + (unlistedData[0]?.paid_reservations_L30D ?? 0),
            or_prev:
            (listedData[0]?.owners_occupancy_L30D_L60D ?? 0) + (unlistedData[0]?.owners_occupancy_L30D_L60D ?? 0) +
            (listedData[0]?.paid_reservations_L30D_L60D ?? 0) + (unlistedData[0]?.paid_reservations_L30D_L60D ?? 0),
          };
        case N30D:
          return {
            ownersBlocksRatio: (listedData[0]?.owners_occupancy_N30D ?? 0) + (unlistedData[0]?.owners_occupancy_N30D ?? 0),
            paidReservationsRatio: (listedData[0]?.paid_reservations_N30D ?? 0) + (unlistedData[0]?.paid_reservations_N30D ?? 0),
            marketOR_current: (listedData[0]?.Market_OR_N30D ?? 0) + (unlistedData[0]?.Market_OR_N30D ?? 0),
            marketOR_prev: (listedData[0]?.Market_OR_L30D ?? 0) + (unlistedData[0]?.Market_OR_L30D ?? 0),
            or_current:
            (listedData[0]?.owners_occupancy_N30D ?? 0) + (unlistedData[0]?.owners_occupancy_N30D ?? 0) +
            (listedData[0]?.paid_reservations_N30D ?? 0) + (unlistedData[0]?.paid_reservations_N30D ?? 0),
            or_prev:
            (listedData[0]?.owners_occupancy_L30D ?? 0) + (unlistedData[0]?.owners_occupancy_L30D ?? 0) +
            (listedData[0]?.paid_reservations_L30D ?? 0) + (unlistedData[0]?.paid_reservations_L30D ?? 0),
          };
        default:
          return {
            ownersBlocksRatio: 0,
            paidReservationsRatio: 0,
            marketOR_current: 0,
            marketOR_prev: 0,
            or_current: 0,
            or_prev: 0,
          };
      }
      
    } else if (filterYes) {
      // Only listed data
      generalData = listedData[0];
    } else if (filterNo) {
      // Only not-listed data
      generalData = unlistedData[0];
    }     
    
      switch (period) {
        case L30D:
          return {
            ownersBlocksRatio: (generalData?.owners_occupancy_L30D ?? 0),
            paidReservationsRatio: (generalData?.paid_reservations_L30D ?? 0),
            marketOR_current: (generalData?.Market_OR_L30D ?? 0),
            marketOR_prev: (generalData?.Market_OR_L30_60D ?? 0),
            or_current:
            (generalData?.owners_occupancy_L30D ?? 0) +
            (generalData?.paid_reservations_L30D ?? 0),
            or_prev:
            (generalData?.owners_occupancy_L30D_L60D ?? 0) +
            (generalData?.paid_reservations_L30D_L60D ?? 0),
          };
        case N30D:
          return {
            ownersBlocksRatio: (generalData?.owners_occupancy_N30D ?? 0),
            paidReservationsRatio: (generalData?.paid_reservations_N30D ?? 0),
            marketOR_current: (generalData?.Market_OR_N30D ?? 0),
            marketOR_prev: (generalData?.Market_OR_L30D ?? 0),
            or_current:
            (generalData?.owners_occupancy_N30D ?? 0) +
            (generalData?.paid_reservations_N30D ?? 0),
            or_prev:
            (generalData?.owners_occupancy_L30D ?? 0) +
            (generalData?.paid_reservations_L30D ?? 0),
          };
        default:
          return {
            ownersBlocksRatio: 0,
            paidReservationsRatio: 0,
            marketOR_current: 0,
            marketOR_prev: 0,
            or_current: 0,
            or_prev: 0,
          };
      }
    } else {
      return {
        ownersBlocksRatio: 0,
        paidReservationsRatio: 0,
        marketOR_current: 0,
        marketOR_prev: 0,
        or_current: 0,
        or_prev: 0,
      };
    }
  }, [occupancyRates, period, filters]);

  // console.log(occupancyRates);

  const total =
    preparedValues.ownersBlocksRatio + preparedValues.paidReservationsRatio;

  const data = [
    { name: "Owner Blocks", value: preparedValues.ownersBlocksRatio },
    { name: "Paid Reservations", value: preparedValues.paidReservationsRatio },
    {
      name: "empty",
      value:
        1 -
        preparedValues.ownersBlocksRatio -
        preparedValues.paidReservationsRatio,
    },
  ];

  useEffect(() => {
    dispatch({
      type: RESERVATIONS_VS_BLOCKS_OCCUPANCY_REQUEST,
      payload: filters
        ? {
            owners: filters.ownersToRequest,
            properties: filters.propertiesToRequest,
            beds: filters.bedroomsToRequest,
          }
        : {
            beds: [],
            owners: [],
            properties: [],
          },
    });
  }, [dispatch, filters]);

  const renderCustomizedLabel = useCallback(
    ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }: any) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      if (name === "empty" || percent === 0) return null;

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {`${toWholePercent(percent)}%`}
        </text>
      );
    },
    []
  );

  return (
    <div className={classes.occupancyRateContainer} id="occupancyBlock">
      <div className={classes.occupancyRateHeader}>
        <div className={classes.headerWithTooltip}>
          <h4 className={classes.header}>Occupancy Rate</h4>
        </div>
        <PeriodDropdown
          className={classes.dropdown}
          period={period}
          periodList={occupancyRatesPeriodList}
          setPeriod={setPeriod}
        />
      </div>
      {!isMobileView ? <Divider /> : null}
      {!filters || filters.listed.no || filters.listed.yes ? (
        <Grid
          container
          direction={isMobileView ? "column" : "row"}
          gap={isMobileView ? "24px" : "79px"}
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Box width="100%" display="flex" justifyContent="center">
              <div className={classes.pieContainer}>
                <ResponsiveContainer
                  width={isMobileView ? 175 : 175}
                  height={isMobileView ? 175 : 175}
                >
                  <PieChart
                    width={isMobileView ? 175 : 175}
                    height={isMobileView ? 175 : 175}
                  >
                    <Pie
                      cx={"50%"}
                      cy={"50%"}
                      data={data}
                      dataKey="value"
                      fill="#8884d8"
                      startAngle={90}
                      endAngle={-270}
                      labelLine={false}
                      label={renderCustomizedLabel}
                      innerRadius={isMobileView ? 45 : 45}
                      outerRadius={isMobileView ? 80 : 80}
                      animationDuration={250}
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={OCCUPANCY_PIE_COLORS[index]}
                          stroke="none"
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                {isNumber(total) ? (
                  <h2 className={classes.total}>{`${(100 * total).toFixed(
                    1
                  )}%`}</h2>
                ) : (
                  <Toast className={classes.toast} type="info" />
                )}
              </div>
            </Box>
            <Legend
              className={classes.legend}
              legendData={occupancyPieLegend}
            />
          </Box>
          <OccupancyRateVsMarket
            or_current={preparedValues.or_current}
            mor_current={preparedValues.marketOR_current}
            or_prev={preparedValues.or_prev}
            mor_prev={preparedValues.marketOR_prev}
          />

          <Grid container direction="column" gap="25px" mt="24px" width="auto">
            <h4 style={{ textAlign: "left" }}>
              MPI {period === L30D ? "L30" : "N30"}{" "}
              <BasicTooltip
                tooltip={
                  "This index measures the property's rate of bookings relative to the total market, indicating its competitive performance. It is calculated as follows MPI= OR / Market OR"
                }
              />
            </h4>
            <Box className={classes.mpiWrapper}>
              <h1
                style={{
                  margin: "0px",
                  marginBottom: isMobileView ? "0px" : "16px",
                  fontWeight: "500",
                  color: "#02094F",
                }}
              >
                {(
                    preparedValues.marketOR_current 
                    && preparedValues.marketOR_current !== 0
                  ) 
                    ? (preparedValues.or_current / preparedValues.marketOR_current).toFixed(2)
                    : '0.00'
                  }
              </h1>
              <DynamicIndicator
                previous={preparedValues.or_prev / preparedValues.marketOR_prev}
                current={
                  preparedValues.or_current / preparedValues.marketOR_current
                }
                isReversed
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <h3 style={{ margin: 0 }}>No data</h3>
        </Box>
      )}
    </div>
  );
};
