import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./RevenueByMonth.module.scss";
import { GET_REVENUE_BY_MONTH_REQUEST } from "../../../../../redux/actions";
import {
  shortMonthsNames,
  yearsList,
} from "../../../../../utils/constants/periodLists";
import { Legend } from "../../../../common/ChartLegend/ChartLegend";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { revenueByMonthSelector } from "../../../../../redux/selectors/revenueSelector";
import { IYearlyRevenue } from "../../../../interfaces";
import {
  findMaxNumericValue,
  getCurrentYearString,
  getPreviousYearString,
} from "../../../../../utils/commonUtils";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { Divider, IconButton } from "@mui/material";
import { BarIcon } from "../../../../../assets/icons/BarIcon";
import { LineIcon } from "../../../../../assets/icons/LineIcon";
import { RevenueByMonthBarChart } from "./components/RevenueByMonthBarChart/RevenueByMonthBarChart";
import { RevenueByMonthLineChart } from "./components/RevenueByMonthLineChart/RevenueByMonthLineChart";
import {
  performanceDataFilterSelector,
  performanceRevenueFilterSelector,
} from "../../../../../redux/selectors/performanceDataSelector";
import { CheckboxRevenueOption } from "../../../PerfomancePage/components/FiltersContainer/components/RevenuePopover/RevenuePopover";

export const RevenueByMonth = () => {
  const dispatch = useDispatch();
  const filters = useSelector(performanceDataFilterSelector);
  const revenue_filters = useSelector(performanceRevenueFilterSelector);

  const isAdmin = +(sessionStorage.getItem("ROLE_TYPE") || 1) === 1;
  const isSubAdmin = +(sessionStorage.getItem("ROLE_TYPE") || 2) === 2;

  const [fromYear, setFromYear] = useState(getPreviousYearString());
  const [toYear, setToYear] = useState(getCurrentYearString());
  const [chartType, setChartType] = useState("bar");
  const [maxRevenueValue, setMaxRevenueValue] = useState(0);
  const isBarActive = chartType === "bar";

  const fromYearData = useSelector(revenueByMonthSelector(fromYear));
  const toYearData = useSelector(revenueByMonthSelector(toYear));
  const isMobileView = useSelector(isMobileModeSelector);

  const years = yearsList();

  const revenueBarLegend = [
    {
      label: fromYear,
      color: "#BDE0D9",
      legendType: "circle",
    },
    {
      label: toYear,
      color: "#009978",
      legendType: "circle",
    },
  ];

  // const data =
  //   fromYearData && toYearData
  //     ? fromYearData.map(
  //         (
  //           {
  //             accommodation_fare_adjusted_usd,
  //             total_fee_usd,
  //             host_channel_fee_usd,
  //             Revenue,
  //           }: IYearlyRevenue,
  //           index: number
  //         ) => {
  //           const toYearDataFees = toYearData[index];

  //           const fromYearFees = revenue_filters
  //             ? revenue_filters.reduce(
  //                 (acc: number, current: CheckboxRevenueOption) =>
  //                   acc +
  //                   (current.checked
  //                     ? current.value === "+"
  //                       ? fromYearData[index][current.key]
  //                       : -fromYearData[index][current.key]
  //                     : 0),
  //                 0
  //               )
  //             : isAdmin || isSubAdmin
  //             ? Revenue
  //             : accommodation_fare_adjusted_usd +
  //               total_fee_usd -
  //               host_channel_fee_usd;

  //           const toYearFees = toYearDataFees
  //             ? revenue_filters
  //               ? revenue_filters.reduce(
  //                   (acc: number, current: CheckboxRevenueOption) =>
  //                     acc +
  //                     (current.checked
  //                       ? current.value === "+"
  //                         ? toYearDataFees[current.key]
  //                         : -toYearDataFees[current.key]
  //                       : 0),
  //                   0
  //                 )
  //               : isAdmin || isSubAdmin
  //               ? toYearDataFees.Revenue
  //               : toYearDataFees.accommodation_fare_adjusted_usd +
  //                 toYearDataFees.total_fee_usd -
  //                 toYearDataFees.host_channel_fee_usd
  //             : 0;

  //           return {
  //             name: shortMonthsNames[index],
  //             [fromYear]: fromYearFees,
  //             [toYear]: toYearFees,
  //           };
  //         }
  //       )
  //     : null;
  const data = useMemo(() => {
    if (!fromYearData || !toYearData) return {};  
    // Filtered data for is_listed == 1 (first 12 months)
    const fromYearUnlistedData = fromYearData.slice(0, 12);
    var fromYearListedData = fromYearData.slice(12, 24);
    const toYearUnlistedData = toYearData.slice(0, 12);
    var toYearListedData = toYearData.slice(12, 24);

    if(fromYearListedData.length > 0 && toYearListedData.length > 0)
    {     
        toYearListedData = toYearData.slice(12, 24); 
        fromYearListedData = fromYearData.slice(12, 24);
    }else{
        toYearListedData = toYearUnlistedData; 
        fromYearListedData = fromYearUnlistedData;
    }    
    const UnlistedData = fromYearUnlistedData.map(
      (
        {
          accommodation_fare_adjusted_usd,
          total_fee_usd,
          host_channel_fee_usd,
          Revenue,
        }: IYearlyRevenue,
        index: number
      ) => {
        const toYearDataFees = toYearUnlistedData[index];

        const fromYearFees = revenue_filters
          ? revenue_filters.reduce(
              (acc: number, current: CheckboxRevenueOption) =>
                acc +
                (current.checked
                  ? current.value === "+"
                    ? fromYearUnlistedData[index][current.key]
                    : -fromYearUnlistedData[index][current.key]
                  : 0),
              0
            )
          : isAdmin || isSubAdmin
          ? Revenue
          : accommodation_fare_adjusted_usd +
            total_fee_usd -
            host_channel_fee_usd;

        const toYearFees = toYearDataFees
          ? revenue_filters
            ? revenue_filters.reduce(
                (acc: number, current: CheckboxRevenueOption) =>
                  acc +
                  (current.checked
                    ? current.value === "+"
                      ? toYearDataFees[current.key]
                      : -toYearDataFees[current.key]
                    : 0),
                0
              )
            : isAdmin || isSubAdmin
            ? toYearDataFees.Revenue
            : toYearDataFees.accommodation_fare_adjusted_usd +
              toYearDataFees.total_fee_usd -
              toYearDataFees.host_channel_fee_usd
          : 0;

        return {
          name: shortMonthsNames[index],
          [fromYear]: fromYearFees,
          [toYear]: toYearFees,
        };
      }
    );

    const listedData = fromYearListedData.map(
      (
        {
          accommodation_fare_adjusted_usd,
          total_fee_usd,
          host_channel_fee_usd,
          Revenue,
        }: IYearlyRevenue,
        index: number
      ) => {
        const toYearDataFees = toYearListedData[index];

        const fromYearFees = revenue_filters
          ? revenue_filters.reduce(
              (acc: number, current: CheckboxRevenueOption) =>
                acc +
                (current.checked
                  ? current.value === "+"
                    ? fromYearListedData[index][current.key]
                    : -fromYearListedData[index][current.key]
                  : 0),
              0
            )
          : isAdmin || isSubAdmin
          ? Revenue
          : accommodation_fare_adjusted_usd +
            total_fee_usd -
            host_channel_fee_usd;

        const toYearFees = toYearDataFees
          ? revenue_filters
            ? revenue_filters.reduce(
                (acc: number, current: CheckboxRevenueOption) =>
                  acc +
                  (current.checked
                    ? current.value === "+"
                      ? toYearDataFees[current.key]
                      : -toYearDataFees[current.key]
                    : 0),
                0
              )
            : isAdmin || isSubAdmin
            ? toYearDataFees.Revenue
            : toYearDataFees.accommodation_fare_adjusted_usd +
              toYearDataFees.total_fee_usd -
              toYearDataFees.host_channel_fee_usd
          : 0;

        return {
          name: shortMonthsNames[index],
          [fromYear]: fromYearFees,
          [toYear]: toYearFees,
        };
      }
    );

    interface MonthlyData {
      [key: string]: number | string; // Dynamic keys (year values) and name as string
      name: string;
    }
    // Apply the filters for listed and unlisted data
    const filterYes = filters?.listed?.yes ?? true;
    const filterNo = filters?.listed?.no ?? false;
    const filterBeds = filters?.ownersToRequest?.length> 0 ? filters.ownersToRequest.length : false;

    if (filterYes && filterNo && !filterBeds) {
      return listedData.map((item: MonthlyData, index: number) => {
        const unlistedItem = UnlistedData[index];
      
        const combinedItem: MonthlyData = { name: item.name };
      
        // Iterate dynamically over all keys in the item
        for (const key in item) {
          if (key !== 'name') {
            combinedItem[key] = (item[key] as number || 0) + (unlistedItem[key] as number || 0);
          }
        }
      
        return combinedItem;
      });       
    } else if (filterYes) {
      return listedData;
    } else if (filterNo) {
      return UnlistedData;
    }

  }, [fromYearData, toYearData, filters]);

  useEffect(() => {
    if (data) {
      setMaxRevenueValue(findMaxNumericValue(data));
    }
  }, [data]);

  useEffect(() => {
    if (fromYear !== toYear) {
      dispatch({
        type: GET_REVENUE_BY_MONTH_REQUEST,
        payload: filters
          ? {
              owners: filters.ownersToRequest,
              properties: filters.propertiesToRequest,
              beds: filters.bedroomsToRequest,
              year: [fromYear, toYear],
            }
          : { owners: [], properties: [], beds: [], year: [fromYear, toYear] },
      });
    }
  }, [dispatch, filters, fromYear, toYear]);

  return (
    <div className={classes.wrapper} id="revenue">
      <div className={classes.revenueBarContainer}>
        <div className={classes.revenueBarHeader}>
          <div className={classes.revenueBarHeaderLeft}>
            <h4 style={{ fontSize: isMobileView ? "16px" : "24px" }}>
              Earned Revenue:
            </h4>
            <div className={classes.periodBlock}>
              <PeriodDropdown
                period={fromYear}
                periodList={years}
                setPeriod={setFromYear}
                variant="standard"
              />
              <h4 className={classes.centerSelfAlign}> vs. </h4>
              <PeriodDropdown
                period={toYear}
                periodList={years}
                setPeriod={setToYear}
                variant="standard"
              />
            </div>
            {!isMobileView ? <Divider flexItem orientation="vertical" /> : null}
            {!isMobileView && <Legend legendData={revenueBarLegend} />}
          </div>
          {!isMobileView && (
            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          )}
        </div>
        {isBarActive
          ? data && (
              <RevenueByMonthBarChart
                data={data}
                fromYear={fromYear}
                toYear={toYear}
                maxRevenueValue={maxRevenueValue}
              />
            )
          : data && (
              <RevenueByMonthLineChart
                data={data}
                fromYear={fromYear}
                toYear={toYear}
                maxRevenueValue={maxRevenueValue}
              />
            )}
        {isMobileView && (
          <div className={classes.legendRow}>
            <Legend legendData={revenueBarLegend} />

            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
